<template>
  <v-btn
    x-large
    width="100%"
    outlined
    class="auth-button-doxy"
    :height="$vuetify.breakpoint.smAndUp ? 64 : 60"
    @click="signInAndRedirect"
  >
    <img
      class="mr-6 mr-sm-4"
      src="@/assets/doxyme.svg"
      alt="doxyme"
      width="32"
      height="32"
    >
    Continue with Doxy.me
  </v-btn>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'AuthDoxyme',
  data() {
    return {
      domainMap: {
        'localhost': 'https://doxy-cognac.me/sign-in?sso=adherely-dev',
        'adherely-dev.firebaseapp.com': 'https://doxy-cognac.me/sign-in?sso=adherely-dev',
        'adherely-stage.firebaseapp.com': 'https://doxy-cognac.me/sign-in?sso=adherely-stage',
        'app.adhere.ly': 'https://doxy.me/sign-in?sso=adherely',
        default: 'https://doxy.me/sign-in?sso=adherely'
      },
      subdomain: ''
    }
  },
  mounted() {
    this.subdomain = window.location.hostname
  },
  methods: {
    ...mapActions('authentication', ['doxymeSignIn']),
    ...mapMutations('app', ['setLoginWindow']),
    async signInAndRedirect() {
      const domain = this.domainMap[this.subdomain] 
        ? this.domainMap[this.subdomain]
        : this.domainMap.default
      const loginWindow = window.open(domain, 
        '_blank', 
        'width=480,height=640,toolbar=0,location=0,menubar=0')
      // set loginWindow so we know we know in ssoSignin.js whether 
      // or not we've logged in via new window so we can close it
      // and redirect back to the opener
      loginWindow.sessionStorage.setItem('loginWindow', true)
    },
  }
}
</script>
<style scoped>
  .auth-button-doxy {
    background-color: #fff !important;
    background-image: none !important;
    border: 1px solid #65CCCC;
  }

  .auth-button-doxy:hover {
    background-color: rgba(101, 204, 204, 0.2) !important;
    transition: background-color 0.5s ease;
  }  

  >>>.v-btn__content {
    color: #65CCCC !important;
  }
</style>
