<template>
  <v-form
    ref="form"
    v-model="valid"
    class="login-form"
    @submit.prevent="$emit('submit-action')"
  >
    <v-row no-gutters>
      <div class="text-center">
        <v-snackbar
          v-model="snackbar"
          class="snackbar"
          :timeout="timeout"
          top
          color="white"
        >
          <img
            v-if="snackType === 'info'"
            class="icon--success"
            src="@/assets/icon-check--green.svg"
            alt="Success checkmark"
          >
          <img
            v-if="snackType === 'error' || snackType === 'verifyError'"
            class="icon--error"
            src="@/assets/icon-error.svg"
            alt="Icon to close dialog"
          >
          <span
            v-if="notice && snackType == 'verifyError'"
            class="snackbar--text"
          >
            Account was not verified.
            <span
              class="notify-link"
              @click="resendEmail"
            >
              Send email with verification link.
            </span></span>
          <span
            v-else
            class="snackbar--text"
          >{{ snackText }}</span>
          <template v-slot:action="{ attrs }">
            <v-btn
              color="black"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              <img
                class="icon--close"
                src="@/assets/icon-close.svg"
                alt="Icon to close dialog"
              >
            </v-btn>
          </template>
        </v-snackbar>
      </div>

      <slot />

      <v-col class="col-12 d-flex justify-center py-0">
        <v-btn
          x-large
          width="100%"
          :height="$vuetify.breakpoint.smAndUp ? 64 : 60"
          color="primary"
          type="submit"
          :disabled="!valid"
          @click="validate"
        >
          {{ submitButtonText }}
        </v-btn>
      </v-col>

      <slot name="afterSubmitButton" />

      <v-col
        v-if="$slots.footer"
        class="col-12 py-0 login-form-footer text-center"
      >
        <slot name="footer" />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'AuthFormLayout',
  props: {
    submitButtonText: {
      type: String,
      required: false,
      default: 'Submit'
    }
  },
  data() {
    return {
      valid: true,
      snackbar: false,
      timeout: 10000,
      snackText: '',
      snackType: ''
    }
  },
  computed: mapGetters('authentication', ['notice']),
  watch: {
    notice(noticeUpdate) {
      if (noticeUpdate?.message) {
        this.snackbar = true
        this.snackText = noticeUpdate.message
        this.snackType = noticeUpdate.type
      }
    }
  },
  methods: {
    ...mapActions('authentication', ['resendEmail']),
    validate() {
      if (!this.$refs.form.validate()) {
        this.snackbar = true
      }
    }
  }
}
</script>

<style lang="scss">
  .snackbar {
    .icon {
      &--success {
        margin-bottom: -7px;
        margin-right: 12px;
      }

      &--error {
        margin-bottom: -7px;
        margin-right: 12px;
      }
    }

    &--text {
      color: #192333;
    }
  }

.login-form {
  .v-input__slot {
    margin-bottom: 1px;
    padding: 0 22px !important;

    input::placeholder {
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase;
    }
  }

  .v-text-field__details {
    margin-bottom: 1px !important;
  }

  .forgot-password {
    margin-bottom: 20px;
    font-size: 14px;
    padding-left: 5px;
  }

  &-footer {
    margin-top: 104px;
    font-size: 16px;

    @media (max-width: 959px) {
      margin-top: 60px;
    }
  }
}
</style>