<template>
  <v-btn
    x-large
    width="100%"
    outlined
    class="auth-button-google"
    :height="$vuetify.breakpoint.smAndUp ? 64 : 60"
    @click="signInAndRedirect"
  >
    <img
      class="mr-6 mr-sm-4"
      src="@/assets/google.svg"
      alt=""
    >
    Continue with Google
  </v-btn>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'AuthGoogle',
  methods: {
    ...mapActions('authentication', ['googleSignIn']),
    async signInAndRedirect() {
      this.$store.dispatch('app/loading', true)
      const user = await this.googleSignIn()

      if (!user) {
        // User login error is handled in the authentication/googleSignIn action (a notice is dispatched)
        return
      }
      if (!Object.prototype.hasOwnProperty.call(user, 'signInAt')) {
        // First sign in - immediately after signing in, the signInAt prop is set to the current date
        // New users won't have this prop in vuex (unless they reload the page)
        
        await this.$router.replace({
          name: 'welcome'
        })
      } else {
        await this.$router.push({
          name: 'dashboard'
        }).catch(() => {})
      }
    }
  }
}
</script>
<style scoped>
  .auth-button-google {
    background-color: #fff !important;
    background-image: none !important;
    border: 1px solid #27201F;
  }

  .auth-button-google:hover {
    background-color: rgba(39, 32, 31, 0.2) !important;
    transition: background-color 0.5s ease;
  }  

  >>>.v-btn__content {
    color: rgb(39, 32, 31) !important;
  }
</style>