<template>
  <span class="auth-toggle-header">
    <router-link
      :to="{ name: 'signIn' }"
      class="text-decoration-none"
    >
      Login
    </router-link>
    <router-link
      :to="{ name: 'signUp' }"
      class="text-decoration-none"
    >
      Sign Up
    </router-link>
  </span>
</template>

<script>
export default {
  name: 'AuthToggleHeader'
}
</script>

<style scoped lang="scss">
.auth-toggle-header {
  a {
    margin: 0 8px;
    color: var(--v-black-base);

    &:not(.router-link-active) {
      opacity: 0.3;
    }
  }
}
</style>
